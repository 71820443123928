import React from "react";
import { Table } from "@radix-ui/themes";

const TableComponent = ({ columnHeaders, paginatedRows, sortConfig, handleSortChange, renderCellContent, isLoading }) => {
  return (
    <Table.Root variant="surface" layout="auto">
      {/* Table Header */}
      <Table.Header>
        <Table.Row>
          {columnHeaders.map((header, index) => (
            <Table.ColumnHeaderCell
              key={index}
              onClick={() => header.sortable && handleSortChange(header.key)}
              style={{ cursor: header.sortable ? "pointer" : "default", position: "relative" }}
            >
              {header.name}
              {header.sortable && sortConfig.key === header.key && (
                <span style={{ position: "absolute", right: "8px", top: "50%", transform: "translateY(-50%)" }}>
                  {sortConfig.direction === "asc" ? "▲" : "▼"}
                </span>
              )}
            </Table.ColumnHeaderCell>
          ))}
        </Table.Row>
      </Table.Header>

      {/* Table Body */}
      <Table.Body>
        {
        paginatedRows.length === 0 && isLoading ? (
          <Table.Row>
          <Table.Cell colSpan={columnHeaders.length}>
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
              <div className="spinner-grow text-primary" role="status"></div>
              <span className="ms-2 fw-semibold" style={{ fontSize: '20px' }}>Loading Active Data Sources...</span>
            </div>
          </Table.Cell>
        </Table.Row>
        ) :
        paginatedRows.length === 0 ? (
          // Show message when no active data sources are available
          <Table.Row>
            <Table.Cell colSpan={columnHeaders.length}>
              <div className="d-flex justify-content-center mt-3">
                <div className="alert alert-info" role="alert">
                  No Active Data Sources, Click Above To Add A Configuration
                </div>
              </div>
            </Table.Cell>
          </Table.Row>
        ) : 
        paginatedRows.map((row, rowIndex) => (
          <Table.Row key={rowIndex}>
            {columnHeaders.map((header, headerIndex) => (
              <Table.Cell key={headerIndex}>
                {renderCellContent(header, row, rowIndex)}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};

export default TableComponent;
