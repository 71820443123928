import React from 'react';
import DataTable from './DataTable';
import useAddedFulfillmentSources from '../../hooks/useAddedFulfillmentSources'; // Import the hook


function DataSources() {

    const { savedsources, isLoading, errorMessage, reloadAddedSources } = useAddedFulfillmentSources();



    // if (isLoading) {
    //     return (
    //         <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
    //             <div className="spinner-grow text-primary" role="status"></div>
    //             <span className="ms-2 fw-semibold" style={{ fontSize: '20px' }}>Loading Active Data Sources...</span>
    //         </div>
    //     );
    // }

    // if (savedsources.length === 0) {
    //     return (
    //         <div className="d-flex justify-content-center mt-3">
    //             <div className="alert alert-info" role="alert">
    //                 No Active Data Sources, Click Above To Add A Configuration
    //             </div>
    //         </div>
    //     );
    // }

    return (
        <div>
            <DataTable rawData={savedsources} reload={reloadAddedSources} isLoading={isLoading} />
        </div>
    );
}

export default DataSources;
