import React, { useState } from 'react';
import axios from 'axios';
import CommonModal from '../../common_jsx/CommonModal'
import config from '../../config.json';

function DataSourceDeleteModal({ savedsource, showDeleteModal, setShowDeleteModal, setConductReload }) {

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [isDeleted, setIsDeleted] = useState(false); // New state for tracking deletion status
    // State for Loading Animation 
    const [isLoading, setIsLoading] = useState(false);
    // Function for Delete Request
    const HandleSubmit = async (e, savedsource) => {
        e.preventDefault();
        // Start the loading animation
        setIsLoading(true);
        const config_home = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            data: savedsource
        };
        try {
            const response = await axios.delete(`${config.serviceUrl}/fulfillment-source/delete`, config_home);
            // Set success alert
            setAlertMessage(response.data.message);
            setAlertType('success');
            setShowAlert(true);
            setIsDeleted(true); 
            setConductReload();
        } catch (error) {
            console.error('There was an error!', error);
            // Set error alert
            setAlertMessage(error.message);
            setAlertType('danger');
            setShowAlert(true);
        }
        setIsLoading(false);
    };

    const deleteBody = (
        <>
            {showAlert ? (
                <div className={`alert alert-${alertType}`} role="alert">
                    {alertMessage}
                </div>
            ) : (
                <p>Are you sure you want to delete this configuration?</p>
            )}

            <form onSubmit={(e) => HandleSubmit(e, savedsource)}>
                <button type="submit" className="btn btn-danger" disabled={isDeleted}>
                    {isLoading &&
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only"></span>
                        </div>}
                    {isLoading ? ' Working...' : 'Delete'}
                </button>
            </form>
        </>
    )

    const deleteDataSourceContent = {
        heading: "Delete Data Source",
        body: deleteBody,
        isLoading: isLoading
    }


    return (
        <CommonModal
            show={showDeleteModal}
            setShow={setShowDeleteModal}
            modalContent={deleteDataSourceContent}
        />
    )
}

export default DataSourceDeleteModal
