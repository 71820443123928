import { useState, useEffect } from 'react';
import { loadAddedFulfillmentSources } from '../service/apiFullfilmentService';

const useAddedFulfillmentSources = () => {
    const [savedsources, setSavedSources] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [reload, setReload] = useState(false);  // Added reload state inside the hook

    useEffect(() => {
        const fetchAddedSources = async () => {
            try {
                setIsLoading(true);
                const addedSources = await loadAddedFulfillmentSources();
                setSavedSources(addedSources);
            } catch (error) {
                setErrorMessage('Unable to load added fulfillment sources.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchAddedSources();
    }, [reload]);  // Fetch data when reload state changes

    // Method to trigger the reload
    const reloadAddedSources = () => setReload(!reload);

    return { savedsources, isLoading, errorMessage, reloadAddedSources };
};

export default useAddedFulfillmentSources;
