// utils.js
export const formatDate = (isoDate) => {
    if (!isoDate) return "No Date Available";
    return new Date(isoDate).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };
  
export const getCreatedDate = (sourceId, summaryData) => {
  const summaries = summaryData[sourceId];

  if (!Array.isArray(summaries) || summaries.length === 0) {
    return "No Date Available";
  }

  const validSummaries = summaries.filter((item) => item && item.start_time);

  if (validSummaries.length === 0) {
    return "No Date Available";
  }

  const earliestCreatedDate = validSummaries.reduce((earliest, current) => {
    return new Date(current.start_time) < new Date(earliest.start_time) ? current : earliest;
  });

  return formatDate(earliestCreatedDate.start_time);
};
  
export const getUpdatedDate = (sourceId, summaryData) => {
  const summaries = summaryData[sourceId];

  if (!Array.isArray(summaries) || summaries.length === 0) {
    return "No Date Available";
  }

  const validSummaries = summaries.filter((item) => item && item.end_time);

  if (validSummaries.length === 0) {
    return "No Date Available";
  }

  const latestUpdatedDate = validSummaries.reduce((latest, current) => {
    return new Date(current.end_time) > new Date(latest.end_time) ? current : latest;
  });

  return formatDate(latestUpdatedDate.end_time);
};
  

export const getLatestStatus = (sourceId, summaryData) => {
  const summaries = summaryData[sourceId];
  if (!summaries || summaries.length === 0) {
    return {
      status: "Loading",
      explanation: "Loading is still ongoing. Please check back later for updates."
    };
  }

  const latestSummary = summaries.reduce((latest, current) => {
    return new Date(current.end_time) > new Date(latest.end_time) ? current : latest;
  }, summaries[0]);

  // Ensure values are defined and fallbacks to 0 if undefined or null
  const totalFiles = latestSummary.total_files || 0;
  const failedFiles = latestSummary.failed_files || 0;
  const processedFiles = latestSummary.processed_files || 0;
  const duplicateFiles = latestSummary.duplicate_files || 0;

  // Communication messages based on counts
  if (latestSummary.status === "In Progress") {
    return {
      status: "In Progress",
      explanation: "Processing is still ongoing. Please check back later for updates."
    };
  } else if (latestSummary.status === "Processed") {
    // No files to process
    if (totalFiles === 0) {
      return {
        status: "Unknown",
        explanation: "No files to process."
      };
    }

    // All Success
    if (processedFiles === totalFiles && failedFiles === 0 && duplicateFiles === 0) {
      return {
        status: "Success",
        explanation: "Processing complete. All files were successfully processed."
      };
    }

    // All Fail
    if (failedFiles === totalFiles && processedFiles === 0 && duplicateFiles === 0) {
      return {
        status: "Failed",
        explanation: "Processing complete. Unfortunately, all files failed to process."
      };
    }

    // All Duplicate (Skipped)
    if (duplicateFiles === totalFiles && processedFiles === 0 && failedFiles === 0) {
      return {
        status: "Duplicate",
        explanation: "Processing complete. All files were duplicates and were not processed."
      };
    }

    // Some Success, Some Fail, Some Duplicate
    if (processedFiles > 0 && failedFiles > 0 && duplicateFiles > 0) {
      return {
        status: "Partial Success",
        explanation: "Processing complete. Some files failed to process, some were duplicates, but others were successfully processed."
      };
    }

    // Some Success, Some Fail
    if (processedFiles > 0 && failedFiles > 0 && duplicateFiles === 0) {
      return {
        status: "Partial Success",
        explanation: "Processing complete. Some files failed to process, but others were successfully processed."
      };
    }

    // Some Success, Some Duplicate
    if (processedFiles > 0 && duplicateFiles > 0 && failedFiles === 0) {
      return {
        status: "Success on New Files",
        explanation: "Processing complete. All new files were successfully processed. Duplicate files were not processed."
      };
    }

    // Some Duplicate, Some Fail
    if (duplicateFiles > 0 && failedFiles > 0 && processedFiles === 0) {
      return {
        status: "Failed on New Files",
        explanation: "Processing complete. All new files failed to process."
      };
    }

    // Success and Duplicate (No Fail)
    if (processedFiles > 0 && duplicateFiles > 0 && failedFiles === 0) {
      return {
        status: "Success on New Files",
        explanation: "Processing complete. All new files were successfully processed. Duplicate files were not processed."
      };
    }
  }

  return {
    status: "Unknown",
    explanation: "An unknown status occurred. Please check the process."
  };
};


export const getStatus = (process) => {
  const { status, failed_files, total_files, processed_files, duplicate_files } = process;

  // Determine status based on logic
  if (status === "In Progress") {
    return {
      status: "In Progress",
      explanation: "Processing is still ongoing. Please check back later for updates."
    };
  } else if (status === "Processed") {
    const totalFiles = total_files;
    const failedFiles = failed_files;
    const processedFiles = processed_files;
    const duplicateFiles = duplicate_files;
    const successFiles = processedFiles - failedFiles; // Success files are the total processed minus failed

    // Communication messages based on counts
    if (totalFiles === 0) {
      return {
        status: "Unknown",
        explanation: "No files to process."
      };
    }

    // All Success
    if (processedFiles === totalFiles && failedFiles === 0 && duplicateFiles === 0) {
      return {
        status: "Success",
        explanation: "Processing complete. All files were successfully processed."
      };
    }

    // All Fail
    if (failedFiles === totalFiles && processedFiles === 0 && duplicateFiles === 0) {
      return {
        status: "Failed",
        explanation: "Processing complete. Unfortunately, all files failed to process."
      };
    }

    // All Duplicate (Skipped)
    if (duplicateFiles === totalFiles && processedFiles === 0 && failedFiles === 0) {
      return {
        status: "Duplicate",
        explanation: "Processing complete. All files were duplicates and were not processed."
      };
    }

    // Some Success, Some Fail, Some Duplicate
    if (processedFiles > 0 && failedFiles > 0 && duplicateFiles > 0) {
      return {
        status: "Partial Success",
        explanation: "Processing complete. Some files failed to process, some were duplicates, but others were successfully processed."
      };
    }

    // Some Success, Some Fail
    if (processedFiles > 0 && failedFiles > 0 && duplicateFiles === 0) {
      return {
        status: "Partial Success",
        explanation: "Processing complete. Some files failed to process, but others were successfully processed."
      };
    }

    // Some Success, Some Duplicate
    if (processedFiles > 0 && duplicateFiles > 0 && failedFiles === 0) {
      return {
        status: "Success on New Files",
        explanation: "Processing complete. All new files were successfully processed. Duplicate files were not processed."
      };
    }

    // Some Duplicate, Some Fail
    if (duplicateFiles > 0 && failedFiles > 0 && processedFiles === 0) {
      return {
        status: "Failed on New Files",
        explanation: "Processing complete. All new files failed to process."
      };
    }

    // Success and Duplicate (No Fail)
    if (processedFiles > 0 && duplicateFiles > 0 && failedFiles === 0) {
      return {
        status: "Success on New Files",
        explanation: "Processing complete. All new files were successfully processed. Duplicate files were not processed."
      };
    }
  }

  return {
    status: "Unknown",
    explanation: "An unknown status occurred. Please check the process."
  };
};

export const colors = {
  "Success": "green", 
  "Failed": "red", 
  "In Progress": "amber", 
  "Partial Success": "blue", 
  "Duplicate": "gray", 
  "Failed on New Files": "red", 
  "Success on New Files": "green", 
  "Unknown": "gray" // Added Unknown status for cases like no files to process
};

export const formatDuration = (durationInSeconds) => {
  const hours = Math.floor(durationInSeconds / 3600);  // Get the hours
  const minutes = Math.floor((durationInSeconds % 3600) / 60);  // Get the minutes
  const seconds = Math.round(durationInSeconds % 60);  // Round the seconds to the nearest integer

  if (hours > 0) {
    return `${hours} hr ${minutes} min ${seconds} sec`;
  } else if (minutes > 0) {
    return `${minutes} min ${seconds} sec`;
  } else {
    return `${seconds} sec`;
  }
};
