import { useState, useEffect, useRef, useCallback } from 'react';
import { loadSummaries } from '../service/apiFullfilmentService';  // Import service

const useSummaries = (sources) => {
    const [summaryData, setSummaryData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const summariesFetched = useRef(false);  // Use ref to track if summaries are already fetched

    const fetchSummaries = useCallback(async () => {
        if (sources.length > 0) {
            setIsLoading(true);
            try {
                const summaries = await loadSummaries(sources);  // Call the service to fetch summaries
                setSummaryData(summaries);
                summariesFetched.current = true;  // Mark summaries as fetched
            } catch (error) {
                setErrorMessage('Unable to fetch summaries.');
                console.error('Error fetching summaries:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [sources]);

    useEffect(() => {
        // Fetch summaries only once when sources change
        if (sources.length > 0 && !summariesFetched.current) {
            fetchSummaries();
        }
    }, [sources, fetchSummaries]);  // Dependency on sources, but it only runs once

    // Reload functionality
    const reloadSummaries = () => {
        summariesFetched.current = false;  // Reset the flag to allow fetching again
        fetchSummaries();  // Re-fetch summaries
    };

    return { summaryData, isLoading, errorMessage, reloadSummaries };
};

export default useSummaries;
