import { useState, useEffect, useRef } from 'react';
import { fetchProcessDetails } from '../service/apiFullfilmentService';  // Import service

const useProcessDetails = (processId, fulfillmentSourceId) => {
    const [details, setDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const detailsFetched = useRef(false);  // Track if details are already fetched

    useEffect(() => {
        if (processId && fulfillmentSourceId && !detailsFetched.current) {
            const fetchDetails = async () => {
                setIsLoading(true);
                try {
                    const data = await fetchProcessDetails(processId, fulfillmentSourceId);
                    setDetails(data);
                    detailsFetched.current = true;  // Mark details as fetched
                } catch (error) {
                    setErrorMessage('Unable to fetch process details.');
                    console.error('Error fetching process details:', error);
                } finally {
                    setIsLoading(false);
                }
            };

            fetchDetails();
        }
    }, [processId, fulfillmentSourceId]);  // Dependency on processId and fulfillmentSourceId

    return { details, isLoading, errorMessage };
};

export default useProcessDetails;
