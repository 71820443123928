import axios from 'axios';
import config from '../config.json';


const getAuthConfig = () => {
    const curr_token = localStorage.getItem('token');
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': curr_token,
        },
    };
};

// Service for loading master fulfillment sources
export const loadMasterFulfillmentSources = async () => {
    try {
        const response = await axios.get(`${config.serviceUrl}/fulfillment-sources-master/list`, getAuthConfig());
        if (response.status === 200) {
            return response.data;
        }
        return [];
    } catch (error) {
        console.error('Error loading master fulfillment sources:', error);
        throw error;
    }
};

// Service for loading added fulfillment sources
export const loadAddedFulfillmentSources = async () => {
    try {
        const response = await axios.get(`${config.serviceUrl}/fulfillment-source/list`, getAuthConfig());
        if (response.status === 200) {
            return response.data;
        }
        return [];
    } catch (error) {
        console.error('Error loading added fulfillment sources:', error);
        throw error;
    }
};


// Service for loading summaries
export const loadSummaries = async (sources) => {
    const summaries = sources.reduce((acc, source) => {
        acc[source.id] = []; // Default to an empty array
        return acc;
    }, {});

    for (const source of sources) {
        try {
            const payload = { fulfillment_source_id: source.fullfliment_id };
            const response = await axios.post(
                `${config.serviceUrl}/data_processing_summaries`,
                payload,
                getAuthConfig()
            );

            // Store the summaries for each source
            summaries[source.fullfliment_id] = response.data;
        } catch (error) {
            console.error(
                `Error fetching summaries for source ${source.fullfliment_id}:`,
                error
            );
        }
    }

    return summaries;
};

// Service for fetching process details
export const fetchProcessDetails = async (processId, fulfillmentSourceId) => {
    try {
        const payload = {
            process_id: processId,
            fulfillment_source_id: fulfillmentSourceId,
        };

        const response = await axios.post(
            `${config.serviceUrl}/data_processing_details`,
            payload,
            getAuthConfig()
        );

        return response.data;
    } catch (error) {
        console.error('Error fetching process details:', error);
        throw error;
    }
};