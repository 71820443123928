import React from "react";
import { Flex } from "@radix-ui/themes";
import { Search } from "react-bootstrap-icons";

const SearchBar = ({ searchQuery, setSearchQuery }) => {
  return (
    <Flex justify="start" align="center" mb="4">
      {/* Search input field with search icon */}
      <div style={{ position: "relative", width: "350px", minWidth: "250px" }}>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search..."
          style={{
            padding: "4px 32px 4px 12px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            width: "100%",
          }}
        />
        <span style={{ position: "absolute", right: "8px", top: "50%", transform: "translateY(-50%)", color: "#999" }}>
          <Search />
        </span>
      </div>
    </Flex>
  );
};

export default SearchBar;
