import React from "react";
import * as Select from "@radix-ui/react-select";
import classnames from "classnames";
import {
	CheckIcon,
	ChevronDownIcon,
	ChevronUpIcon,
} from "@radix-ui/react-icons";
import "./select.css";

const SelectOptions = ({ selectedValue, options, onValueChange, placeholder = "Select a data..." }) => {
	return (
		<Select.Root value={selectedValue} onValueChange={onValueChange}>
			<Select.Trigger className="SelectTrigger h-100 border-none shadow-none" aria-label="Data Sources Selector">
				<Select.Value placeholder={placeholder}>
					{renderSelected ? renderSelected(selectedValue) : selectedValue}
				</Select.Value>
				<Select.Icon className="SelectIcon">
					<ChevronDownIcon />
				</Select.Icon>
			</Select.Trigger>
			<Select.Portal>
				<Select.Content className="SelectContent" side="top" align="center">
					<Select.ScrollUpButton className="SelectScrollButton">
						<ChevronUpIcon />
					</Select.ScrollUpButton>
					<Select.Viewport className="SelectViewport">
						<Select.Group>
							<Select.Label className="SelectLabel">Data Sources</Select.Label>
							{options.map((option, index) => (
								<SelectItem key={index} value={option}>
									{renderItem ? renderItem(option) : option.label}
								</SelectItem>
							))}
						</Select.Group>
					</Select.Viewport>
					<Select.ScrollDownButton className="SelectScrollButton">
						<ChevronDownIcon />
					</Select.ScrollDownButton>
				</Select.Content>
			</Select.Portal>
		</Select.Root>
	);
};

const SelectItem = React.forwardRef(
	({ children, className, ...props }, forwardedRef) => {
		return (
			<Select.Item
				className={classnames("SelectItem", className)}
				{...props}
				ref={forwardedRef}
			>
				<Select.ItemText>{children}</Select.ItemText>
				<Select.ItemIndicator className="SelectItemIndicator">
					<CheckIcon />
				</Select.ItemIndicator>
			</Select.Item>
		);
	},
);

// Custom render function to show the selected value with an Avatar
const renderSelected = (value) => {
	return (
		<div className="flex items-center">
			<span>{value?.name}</span>
		</div>
	);
};

// Custom render function for the dropdown items with an Avatar
const renderItem = (option) => (
	<div className="flex items-center">
		<span>{option.name}</span>
	</div>
);

export default SelectOptions;
