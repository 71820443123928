import React, { useState, useEffect } from "react";
import {
  Dialog,
  Card,
  Text,
  Flex,
  ScrollArea,
  Button,
  Badge,
  Separator,
  Strong,
  Grid,
} from "@radix-ui/themes";
import { CircleX, CircleCheck } from 'lucide-react';
import {InfoCircle } from "react-bootstrap-icons";
import { Tooltip } from "@radix-ui/themes";
import { getStatus, colors, formatDuration } from "./utils"
import useProcessDetails from '../../hooks/useProcessDetails'

const HistoryModal = ({ isOpen, onClose, summaryData, sourceId }) => {
  const [processList, setProcessList] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [expandedFiles, setExpandedFiles] = useState({}); // Track expanded state for each file

  const { details, loading, errorMessage } = useProcessDetails(selectedProcess?.process_id, sourceId);

  useEffect(() => {
    if (isOpen && summaryData && summaryData[sourceId]) {
      const processes = [...summaryData[sourceId]].sort(
        (a, b) => new Date(b.end_time) - new Date(a.end_time)
      );

      processes.forEach((process, index) => {
        process.number = processes.length - index;
      });

      setProcessList(processes);
      if (processes.length > 0) {
        setSelectedProcess(processes[0]);
      }
    }
  }, [isOpen, summaryData, sourceId]);

  const expandAll = () => {
    const expandedState = {};
    details.forEach((file) => {
      expandedState[file.document_id] = true;
    });
    setExpandedFiles(expandedState);
  };
  
  const collapseAll = () => {
    const collapsedState = {};
    details.forEach((file) => {
      collapsedState[file.document_id] = false;
    });
    setExpandedFiles(collapsedState);
  };

  const toggleFileCard = (fileId) => {
    setExpandedFiles((prev) => ({
      ...prev,
      [fileId]: !prev[fileId], // Toggle the expanded state for the specific file
    }));
  };
  

  const handleCardClick = (process) => {
    setSelectedProcess(process);
  };

  

  const renderStatusBadge = (value) => {
    const {status, explanation} = value
    return (
      <Tooltip content={explanation}>
        <Badge color={colors[status] || "gray"}>{status} <InfoCircle /></Badge>
      </Tooltip>
    );
  };

  const renderStatus = (value) => {
    return <Badge color={colors[value] || "gray"}>{value} <InfoCircle /></Badge>
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}
    >
      <Dialog.Content
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          width: "70vw", // Increase the width here
          maxWidth: "100%",
          height: "100vh",
          backgroundColor: "white",
          boxShadow: "rgba(0, 0, 0, 0.2) -4px 0px 12px",
          transform: isOpen ? "translateX(0)" : "translateX(100%)",
          transition: "transform 0.3s ease-in-out",
          zIndex: 1000,
          display: "flex",
          flexDirection: "column",
          padding: "16px",
        }}
        className="bg-light"
      >
        <Flex justify="between" align="center" mb="4">
          <Text size="5" weight="bold">
            Process History
          </Text>
          <Button variant="soft" onClick={onClose} className="btn-outline-elastiq">
            Close
          </Button>
          
        </Flex>

      {/* Horizontal Separator Below Title */}
      <Separator
        orientation="horizontal"
        style={{
          width: "100%", // Make the separator span the full width
          marginBottom: "16px",
        }}
      />

       
        <Flex style={{ flex: 1, gap: "16px", overflow: "hidden" }}>

                    {/* Right Side: Detailed View */}
                    <ScrollArea style={{ flex: 1, padding: "8px", height: "100%" }}>
            {loading ? (
              <Text size="3" weight="bold" align="center">
                Loading...
              </Text>
            ) : selectedProcess ? (
              <>
                <Text size="4" weight="bold" mb="4">
                  Process Details: #{selectedProcess.number}
                </Text>

                {/* Summary Data in Two Columns */}
                <Grid columns="4" gap="4" mb="4">
                  <Flex direction="column">
                    <Text size="3">
                      <Strong>Status:</Strong> 
                    </Text>
                    <Flex direction="column"></Flex>
                    <Text size="3">
                      <Strong>Duration:</Strong>
                    </Text>
                    <Text size="3">
                      <Strong>Total Files:</Strong>
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <Text size="3">
                      {renderStatusBadge(getStatus(selectedProcess))}
                    </Text>
                    <Flex direction="column"></Flex>
                    <Text size="3">
                     {formatDuration(selectedProcess.duration_seconds)}
                    </Text>
                    <Text size="3">
                       {selectedProcess.total_files}
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <Text size="3">
                      <Strong>Failed Files:</Strong>
                    </Text>
                    <Text size="3">
                      <Strong>Processed Files:</Strong>
                    </Text>
                    <Text size="3">
                      <Strong>Duplicate Files:</Strong> 
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <Text size="3">
                     {selectedProcess.failed_files}
                    </Text>
                    <Text size="3">
                      {selectedProcess.processed_files}
                    </Text>
                    <Text size="3">
                      {selectedProcess.duplicate_files}
                    </Text>
                  </Flex>
                </Grid>

                {/* Separator Below Summary */}
                <Separator orientation="horizontal" style={{ marginBottom: "16px" }} />

                <Flex justify="between" align="center" mb="4" style={{ gap: "8px" }}>
                  {/* Processed File Logs Text on the Left */}
                  <Text size="4" weight="bold" mt="4" mb="2">
                    Processed File Logs
                  </Text>

                  {/* Buttons on the Right */}
                  <Flex style={{ gap: "8px", marginRight: "5px" }}>
                    <Button
                      onClick={expandAll}
                      variant="soft"
                      color="cyan"
                      style={{ fontWeight: "bold" }}
                    >
                      Expand All
                    </Button>
                    <Button
                      onClick={collapseAll}
                      variant="soft"
                      color="red"
                      style={{ fontWeight: "bold" }}
                    >
                      Collapse All
                    </Button>
                  </Flex>
                </Flex>

                {details.map((file, index) => {
                  const isExpanded = expandedFiles[file.document_id] || false;
                  const statusColors = {
                    "Success": "green",
                    "Failed": "red",
                    "In Progress": "amber",
                    "Partial Success": "blue",
                    "Unknown": "gray",
                    "Duplicate":"gray" 
                };
                  return (
                  <Card key={file.document_id} variant="soft" style={{ margin: "8px", padding: "16px" }}>

                    {/* Expand/Collapse Button */}
                    <Button
                      onClick={() => toggleFileCard(file.document_id)}
                      style={{
                        position: "absolute",
                        bottom: "4px", // Adjust the distance from the bottom
                        right: "16px", // Center horizontally
                        transform: "translateX(-50%)", // Adjust for button's own width
                        fontWeight: "bold",
                        fontSize: "20px",
                        cursor: "pointer",
                        zIndex: "100",
                      }}
                      variant="ghost"
                      color="black"
                    >
                      {isExpanded ? "−" : "+"}
                    </Button>

                    {/* 1 of N Indicator */}
                    <div style={{ 
                      position: "absolute", 
                      top: "8px", 
                      right: "16px", 
                      backgroundColor: "#f0f0f0", 
                      padding: "4px 8px", 
                      borderRadius: "8px", 
                      fontWeight: "bold", 
                      fontSize: "12px" 
                    }}>
                      {details.indexOf(file) + 1} of {details.length}
                    </div>
                    {/* File Data */}
                    <Grid columns="2" gap="4" className="mb-2">
                      <Text size="3">
                        <Strong>File Name:</Strong> {file.file_name}
                      </Text>
                      <Text size="3">
                        <Strong>Status:</Strong> {renderStatus(file.overall_status)}
                      </Text>
                    </Grid>
                    {/* Expanded Content */}
                   {isExpanded && (
                    <>
                      <Grid columns="2" gap="4" style={{ marginTop: "16px" }}>
                        <Text size="3">
                          <Strong>Start:</Strong> {new Date(file.start_time).toLocaleString()}
                        </Text>
                        <Text size="3">
                          <Strong>Path:</Strong> {file.storage_path}
                        </Text>
                        <Text size="3">
                          <Strong>End:</Strong> {new Date(file.end_time).toLocaleString()}
                        </Text>
                        <div></div>
                        <Text size="3" weight="bold">
                        Process Logs:
                          </Text>
                      </Grid>
                    

                    {file.steps.map((step, index) => {
                        const statusColors = {
                            "Success": "green",
                            "Failed": "red",
                            "In Progress": "amber",
                            "Partial Success": "blue",
                            "Unknown": "gray",
                            "Duplicate":"gray" 
                        };

                        // Get the color for the status
                        const borderColor = statusColors[step.status] || "gray";

                        return (
                            <Card
                            key={index}
                            variant="soft"
                            style={{
                                padding: "8px",
                                borderLeft: `4px solid ${borderColor}`, // Dynamic border color
                                margin: "8px",
                            }}
                            >
                            <Grid columns="2" gap="4">
                                <Text size="3">
                               
                                  {borderColor==="green"?
                                  <CircleCheck color={borderColor} />
                                  :
                                  <CircleX color={borderColor}/>
                                  }
                                
                                <Strong> {step.step_name}</Strong> 
                                </Text>
                                {/* <Text size="3">
                                <Strong>Status:</Strong> {renderStatusBadge(step.status)}
                                </Text> */}
                                <Text size="3">
                                <Strong>Duration:</Strong> {step.duration_seconds} seconds
                                </Text>
                                { step.error && 
                                <Text size="3">
                                <Strong>Error:</Strong> {step.error || "None"}
                                </Text>
                                }
                            </Grid>
                            </Card>
                        );
                        })}
                    </>
                   )}
                  </Card>
                )})}
              </>
            ) : (
              <Text size="3" weight="bold" align="center">
                Select a process to view details.
              </Text>
            )}
          </ScrollArea>

          {/* Separator */}
          <Separator orientation="vertical" style={{ height: "100%", width: "1px" }} />

          {/* Left Side: Scrollable List of Cards */}
          <ScrollArea
            style={{
              width: "25%",
              padding: "8px",
              height: "100%",
              direction: "rtl", // Move scrollbar to the left
            }}
          >
            <div style={{ direction: "ltr", padding: "8px" }}> {/* Reset text alignment */}
              {processList.map((process) => (
                <Card
                  key={process.process_id}
                  variant={
                    selectedProcess?.process_id === process.process_id
                      ? "active"
                      : "soft"
                  }
                  onClick={() => handleCardClick(process)}
                  style={{
                    marginBottom: "8px",
                    cursor: "pointer",
                    border:
                      selectedProcess?.process_id === process.process_id
                        ? "2px solid #333"
                        : "1px solid #ddd",
                  }}
                >

                  {/* Date and Time */}
                  <Flex
                    direction="column"
                    align="end"
                    style={{
                      position: "absolute",
                      top: "16px",
                      right: "8px",
                      fontSize: "12px",
                    }}
                  >
                    <Text size="2" weight="bold">
                      {new Date(process.end_time).toLocaleDateString()}
                    </Text>
                    <Text size="2" color="gray">
                      {new Date(process.end_time).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </Text>
                  </Flex>
                  <Flex direction="column" style={{ flex: 1, padding: "8px", paddingTop:"4px" }}>
                    <Text size="3">#{process.number}</Text>
                    <Text size="2" className="mb-2">
                      <Strong>Total Files:</Strong> {process.total_files}
                    </Text>
                    <Text size="2">
                      <Strong>Status:</Strong> {renderStatusBadge(getStatus(process))}
                    </Text>
                  </Flex>
                </Card>
              ))}
            </div>
          </ScrollArea>


        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default HistoryModal;
