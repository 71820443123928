import React, { useState , useEffect} from "react";
import { Container, Flex, Text, Badge, IconButton, Button } from "@radix-ui/themes";
import { Gear, Play, ClockHistory, Trash, ArrowClockwise, InfoCircle } from "react-bootstrap-icons";
import { Spinner } from "@radix-ui/themes";
import SearchBar from "../../components/Search";
import TableComponent from "../../components/Table";
import Pagination from "../../components/Pagination";
import ErrorBoundary from "../../components/ErrorBoundry";
import HistoryModal from "./HistoryModal";
import axios from "axios";
import config from "../../config.json"
import toast, { Toaster } from 'react-hot-toast';
import DataSourceDeleteModal from "./DataSourceDeleteModal";
import DataSourceUpdateModal from "./DataSourceUpdateModal";
import useSummaries from "../../hooks/useSummaries";
import { Tooltip } from "@radix-ui/themes";
import { getLatestStatus, colors, getUpdatedDate, getCreatedDate } from "./utils"




const DataTable = ({rawData, reload, isLoading}) => {
  const columnHeaders = [
    { name: "Name", key: "name", order: 1, sortable: true },
    {
      name: "Source",
      key: "source",
      order: 2,
      sortable: true,
      render: (value) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={value.icon_url}
            alt={value.name}
            style={{ width: "24px", height: "24px", marginRight: "8px" }}
          />
          <span>{value.name}</span>
        </div>
      ),
    },
    { 
      name: "Created Date", 
      key: "fullfliment_id", 
      order: 3, 
      sortable: true ,
      render: (value) => {
        return <span>{getCreatedDate(value, summaryData)}</span>
      }
    },
    { 
      name: "Updated Date", 
      key: "fullfliment_id", 
      order: 4, 
      sortable: true,
      render: (value) => {
        return <span>{getUpdatedDate(value, summaryData)}</span>
      }
    },
    {
      name: "Status",
      key: "fullfliment_id",
      order: 5,
      sortable: true,
      render: (value) => {
        const {status, explanation} = getLatestStatus(value, summaryData)
        return (
          <Tooltip content={explanation}>
            <Badge color={colors[status] || "gray"}>{status} <InfoCircle /></Badge>
          </Tooltip>
        );
      },
    },
    {
      name: "Action",
      key: "fullfliment_id",
      order: 6,
      sortable: false,
      render: (value, rowIndex) => (
        <Flex gap="3">
          <IconButton
            loading={false}
            variant="soft"
            onClick={() => openUpdateModal(value)}
            className="btn-outline-elastiq"
          >
            <Gear width="18" height="18" />
          </IconButton>
          <IconButton
            loading={currentProcessingId === value && isProcessing}
            variant="solid"
            color="green"
            onClick={() => handleProcessClick({ id: value })}
          >
            <Play width="18" height="18" />
          </IconButton>
          <IconButton
            loading={false}
            variant="surface"
            onClick={() => handleHistoryClick(value)} // Pass the fullfliment_id here
            className="btn-outline-elastiq"
          >
            <ClockHistory width="18" height="18" />
          </IconButton>
          <IconButton
            loading={false}
            variant="solid"
            color="red"
            onClick={() => openDeleteModal(value)}
          >
            <Trash width="18" height="18" />
          </IconButton>
        </Flex>
      ),
    },
  ];

  // Transform rawData to match the table's expected structure
  const rows = rawData.map((item) => ({
    name: item.name,
    source: item.source,
    fullfliment_id: item.id
  }));

  const { summaryData, isLoadingSummaries, errorMessage, reloadSummaries } = useSummaries(rows);

  useEffect(()=>{
    reloadSummaries()
  },[isLoading])


  const [isProcessing, setIsProcessing] = useState(false); // To handle loading state for "Process"
  const [currentProcessingId, setCurrentProcessingId] = useState(null); // To track the row being processed
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  // const [summaryData, setSummaryData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSourceId, setSelectedSourceId] = useState(null)
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dataRow, setDataRow] = useState(null)

  const handleHistoryClick = (id) => {
    setSelectedSourceId(id); // Set the ID of the selected source
    setIsModalOpen(true);
    // Pass processId to the modal if needed
  };

  const getDataRow = (id) => {
    const dataRow = rawData.find((item) => item.id === id); // Use 'find' for a single object
    setDataRow(dataRow); // Assuming setDataRow is used to update the state
  };

  const openUpdateModal = (id)=> {
    getDataRow(id);
    setShowUpdateModal(true)
  }

  const openDeleteModal = (id)=> {
    getDataRow(id);
    setShowDeleteModal(true)
  }

  // Handle "Process" action
  const handleProcessClick = async (selectedDataRow) => {
    setIsProcessing(true);
    setCurrentProcessingId(selectedDataRow.id);
    try {
      const payload = { fulfillment_source_id: selectedDataRow.id };
      const config_home = {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      };
      const response = await axios.post(
        `${config.serviceUrl}/documents/process`,
        payload,
        config_home
      );
      toast.success(response.data.message);
      reload();
      console.log("Process successful:", response.data);
    } catch (error) {
      toast.error("Error during processing. Please try again.");
      console.error("Error during processing:", error);
    } finally {
      setIsProcessing(false);
      setCurrentProcessingId(null);
    }
  };


  const filteredRows = rows.filter((row) =>
    Object.values(row).some((value) =>
      typeof value === "object"
        ? Object.values(value).some((nestedValue) =>
            nestedValue.toString().toLowerCase().includes(searchQuery.toLowerCase())
          )
        : value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const sortedRows = [...filteredRows].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  const paginatedRows = sortedRows.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleSortChange = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const renderCellContent = (header, row, rowIndex) => {
    if (header.render) return header.render(row[header.key], rowIndex);
    return row[header.key];
  };

  return (
    <Container size="4">
    {/* Title */}
    <Flex direction="column" align="start" mb="4">
      <Text size="5" weight="bold" mb="2">
        Knowledge Sources
      </Text>
    </Flex>

    <Flex justify="between" align="center" mt="4" pr="2">
      {/* Search Bar */}
      <ErrorBoundary>
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </ErrorBoundary>

      <IconButton 
        onClick={reload} 
        className={`btn-outline-elastiq ${isLoading ? 'spin' : ''}`}
        variant="soft" 
        disabled={isLoading}
      >
         {
          isLoading ?
          <Spinner />
          :
          <ArrowClockwise />
         }

      </IconButton>
    </Flex>

    {/* Table */}
    <ErrorBoundary>
      <TableComponent
        columnHeaders={columnHeaders}
        paginatedRows={paginatedRows}
        sortConfig={sortConfig}
        handleSortChange={handleSortChange}
        renderCellContent={renderCellContent}
        isLoading={isLoading}
      />
    </ErrorBoundary>

    {/* Pagination */}
    <ErrorBoundary>
      <Pagination
        currentPage={currentPage}
        totalPages={Math.ceil(sortedRows.length / rowsPerPage)}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20, 50]}
        setRowsPerPage={setRowsPerPage}
        handlePreviousPage={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        handleNextPage={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(sortedRows.length / rowsPerPage)))}
      />
    </ErrorBoundary>
    <HistoryModal 
      isOpen={isModalOpen} 
      onClose={() => setIsModalOpen(false)} 
      summaryData={summaryData} 
      sourceId={selectedSourceId} 
    />
    {/* Modals for Update and Delete */}
    {showUpdateModal && (
        <DataSourceUpdateModal
          savedsource={dataRow}
          showUpdateModal={showUpdateModal}
          setShowUpdateModal={setShowUpdateModal}
          setConductReload={reload}
        />
      )}
      {showDeleteModal && (
        <DataSourceDeleteModal
          savedsource={dataRow}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          setConductReload={reload}
        />
      )}
    <Toaster
    position="bottom-center"
     />
  </Container>
  );
};

export default DataTable;
