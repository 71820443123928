import { useEffect } from 'react'
import hljs from 'highlight.js'
import 'highlight.js/styles/atom-one-light.css'

const CodeBlock = ({ language, code }) => {
  useEffect(() => {
    hljs.highlightAll()
  }, [])

  return (
    <pre>
      <code className={language}>
        {code}
      </code>
    </pre>
  )
}

export default CodeBlock
