import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config.json';
import DataSourceDropDown from './DataSourceDropDown'
import DataSources from './DataSources';
import useMasterFulfillmentSources from '../../hooks/useMasterFulfillmentSources'; // Import the hook

function AdminDataSouceScreen() {

    // const [sources, setSources] = useState([]);
    // const [savedsources, setSavedSources] = useState([]);
    // const [isLoading, setIsLoading] = useState(false);
    // const [conductReload, setConductReload] = useState(false);
    const { sources, errorMessage, reload } = useMasterFulfillmentSources();  // Use hook



    // const [errorMessage, setErrorMessage] = useState('');

    // const config_home = {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': localStorage.getItem('token')
    //     }
    // };
    // // Loading sources from master fulfillment sources (used in the dropdown menu)
    // async function loadMasterFulfillmentSources() {
    //     try {
    //         const response = await axios.get(`${config.serviceUrl}/fulfillment-sources-master/list`, config_home);
    //         if (response.status === 200) {
    //             setSources(response.data);
    //         }
    //     } catch (error) {
    //         setErrorMessage(error.response.data.message);
    //         console.log(error);
    //     }
    // }
    // Loading sources for the already configured fulfillment sources (used as cards)
    // async function loadAddedFulfillmentSources() {
    //     try {
    //         setIsLoading(true);
    //         const response = await axios.get(`${config.serviceUrl}/fulfillment-source/list`, config_home);
    //         if (response.status === 200) {
    //             setSavedSources(response.data);
    //         }
    //     } catch (error) {
    //         try {
    //             setErrorMessage(error.response.data.message);
    //         } catch (error) {
    //             setErrorMessage('Unable to Display Fulfillment Sources.');
    //         }
    //     } finally {
    //         setIsLoading(false);
    //     }
    // }

    // Hook for getting fulfillment sources
    // useEffect(() => {
    //     loadMasterFulfillmentSources();
    //     // loadAddedFulfillmentSources();
    // }, [conductReload]);

    return (
        <div className="content bg-light h-100">
            <div style={{ height: "54px" }} className="mb-3"> </div>

            {errorMessage ? <div className="alert alert-danger">{errorMessage}</div>
                :
                <>
                    <DataSourceDropDown
                        sources={sources}
                        setConductReload={reload}
                        errorMessage={errorMessage}
                    />

                    <DataSources
                    />
                </>
            }

        </div>
    )
}

export default AdminDataSouceScreen
