import React from "react";
import { Flex, Text, Button } from "@radix-ui/themes";

const Pagination = ({
  currentPage,
  totalPages,
  rowsPerPage,
  rowsPerPageOptions,
  setRowsPerPage,
  handlePreviousPage,
  handleNextPage,
}) => {
  return (
    <Flex justify="between" align="center" mt="4">
      {/* Pagination info */}
      <Text>{`0 of ${totalPages * rowsPerPage} row(s) selected`}</Text>

      {/* Rows per page selector */}
      <Flex align="center" gap="2">
        <Text>Rows per page:</Text>
        <select
          value={rowsPerPage}
          onChange={(e) => setRowsPerPage(Number(e.target.value))}
          style={{ padding: "4px", borderRadius: "4px", border: "1px solid #ccc" }}
        >
          {rowsPerPageOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </Flex>

      {/* Pagination buttons */}
      <Flex align="center" gap="4">
        <Button variant="outline" className="btn-outline-elastiq" disabled={currentPage === 1} onClick={handlePreviousPage}>
          Previous
        </Button>
        <Text>{`Page ${currentPage} of ${totalPages}`}</Text>
        <Button variant="outline" className="btn-outline-elastiq" disabled={currentPage === totalPages} onClick={handleNextPage}>
          Next
        </Button>
      </Flex>
    </Flex>
  );
};

export default Pagination;
