// MessageInput.jsx
import { useState, useRef, React } from "react";
import MicButton from "./MicButton";

const MessageInput = ({
  value,
  onChange,
  onKeyDown,
  onClick,
  isTyping,
  quotedMessage,
  setQuotedMessage,
  setCurrentParentId,
  setInputText,
}) => {
  const [isMicActive, setIsMicActive] = useState(false);
  const [interimTranscript, setInterimTranscript] = useState("");
  const speechRecognitionRef = useRef(null);
  const closeQuotedMessage = () => {
    setQuotedMessage(null); // Remove the quoted message
    setCurrentParentId(null);
  };

  return (
    <div className="input-group shadow-lg d-flex flex-row">
      <div className="d-flex flex-row flex-grow-1">
        {quotedMessage && (
          <div className="quoted-message d-flex flex-row align-items-center p-3 bg-light rounded-3 mb-0 col-12">
            <p className="mb-0 text-wrap">
              <span className="text-muted">Replying To: </span>
              <br />
              <p className="quoted-message-paragraph">{JSON.stringify(quotedMessage, null, 2)}</p>
            </p>
            <button
              className="btn btn-light ms-auto"
              onClick={closeQuotedMessage}
            >
              &times;
            </button>
          </div>
        )}
      </div>
      <div className="input-group shadow-lg d-flex flex-column flex-grow-1">
        {isMicActive && (
          <div className="d-flex flex-row align-items-center p-3 bg-white rounded-1 mb-0 col-12">
            <p className="bi-nut mb-0 text-wrap"> {interimTranscript}</p>
          </div>
        )}
        <div className="d-flex flex-row align-items-end">
          <textarea
            type="text"
            className="form-control p-3 mb-0 chat-input"
            placeholder="Type your message"
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            style={{ minHeight: "58px" }}
            id="chatInput"
          />
          <button
            className="btn btn-light border p-3"
            onClick={onClick}
            disabled={isTyping}
          >
            <i className="bi bi-send"></i>
          </button>
          <MicButton
            isTyping={isTyping}
            speechRecognitionRef={speechRecognitionRef}
            setInterimTranscript={setInterimTranscript}
            setInputText={setInputText}
            isMicActive={isMicActive}
            setIsMicActive={setIsMicActive}
          />
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
