import { useState, useEffect } from 'react';
import { loadMasterFulfillmentSources } from '../service/apiFullfilmentService';  // Import service

const useMasterFulfillmentSources = () => {
    const [sources, setSources] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [reload, setReload] = useState(false);  // State to trigger reload

    useEffect(() => {
        const fetchSources = async () => {
            try {
                const masterSources = await loadMasterFulfillmentSources();
                setSources(masterSources);
            } catch (error) {
                setErrorMessage('Unable to load master fulfillment sources.');
            }
        };

        fetchSources();
    }, [reload]);  // Trigger fetch whenever reload changes

    const triggerReload = () => setReload(prev => !prev);  // Function to toggle reload state

    return { sources, errorMessage, triggerReload };
};

export default useMasterFulfillmentSources;
